<template>
  <section >
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div class="col-md-12">
      <h2 class="titulo-links-escolha">Editar Aluno</h2>
    </div>
    <div class="card card-steps">
      <Step2 :items="items"/>
    </div>
    <br>
    <infoEndereco  @prevPage="prevPage"   @nextPage="nextPage" :dadosPessoa="dadosPessoa"/>


  </section >
</template>

<script>

import Step2 from '@/components/steps2.vue';
import infoEndereco from '@/components/alunos/infoEndereco.vue';

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props:{
    basic:{},
    pg:{},
  },
  components: {
   Step2,
   infoEndereco
  },
  name: 'dawddsdsdsa',
  data () {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Editar Aluno'},
      ],
      dadosPessoa:[],
      items : [
        {
            number:'1',
            label: 'Informações básicas',
            to: "aluno-basic-info-cadastrar",
            active: 0,
        },
        {
            number:'2',
            label: 'Conectividade e acesso ao sistema',
            to: "2",
            active: 0,
        },
        {
            number:'3',
            label: 'Endereço e Transporte',
            to: "3",
            active: 1,
        },
        {
            number:'4',
            label: 'Confirmar',
            to: "4",
            active: 0,
        },
      ],
    }
  },
  methods: {
    prevPage(info) {
      this.$router.push({ name: "login-pessoal-do-aluno-coordenacao-editar", params: { basic: JSON.stringify(info),pg: this.pg }});
    },
    nextPage(info) {
      this.$router.push({ name: "confirmar-envio-dados-coordenacao-editar", params: { basic: JSON.stringify(info),pg: this.pg }});
    },
  },
  beforeMount() {
    if (this.basic != undefined) {
      const nv = JSON.parse(this.basic);
      this.dadosPessoa = nv.info;
    }
    else {
      this.$router.push({ name: "aluno-basic-info-cadastrar", pg: this.pg});
    }
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
